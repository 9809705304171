import styled from "styled-components";

export const Container = styled.form`
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 500px;
    background-color: white;
    box-shadow: 24;
    padding: 25px 30px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    font-weight: 700;

    > h1 {
      text-align: center;
      font-size: 26px;
    }

    .buttonsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 100%;
    }

    .buttonsContainer button {
        min-width: 180px;
    }

    @media (max-width: 450px) {
        .cancelButton {
            width: 80px;
        }
        .buttonsContainer button {
            min-width: 100px;
        }
    }
`;

export const ContentInput = styled.div`
  width: 100%;
  margin: 0.5rem 0;
`;
