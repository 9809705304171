import { Print, OpenInNew } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    Modal,
    Typography,
    Divider,
    Input,
    Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { If } from "../../components/If";
import InputGeneric from "../../components/InputGeneric";
import ConfirmModal from "../../components/Modal/ConfirmModal";
import PageWrapper from "../../components/PageWrapper";
import PreloadFb from "../../components/Preload";
import Toast from "../../components/Toast";
import { useAuth } from "../../contexts/contexts";
import { formatCurrencyToShow } from "../../helpers/currency";
import { documentMask } from "../../helpers/document";
import { phoneMask } from "../../helpers/phone";
import { api } from "../../services/api";
import {
    FormProposalDetailsPage,
    PaymentCard,
    PaymentCardBody,
} from "./styles";

const PAYMENT_LINK_METHOD = 1;
const PAYMENT_RECURRING_METHOD = 2;
const PAYMENT_CARD_METHOD = 3;

export interface ProposalProps {
    id: number;
    holder_id: number;
    app_plan_id: number;
    grace_day_id: number;
    contract_id: number;
    lead_source_id: number;
    created_at: string | undefined;
    updated_at: string | undefined;
    deleted_at: string | undefined;
    form_link: string | null;
    holder: {
        id: number;
        full_name: string | undefined;
        name: string | undefined;
        document: string;
        is_company: number;
        is_active: number;
        email: string | undefined;
        phone: string;
        address_id: number;
        holder_level_id: number;
        deleted_at: null;
        address: {
            id: 8;
            street: string | undefined;
            street_number: string | undefined;
            neighborhood: string | undefined;
            complement: null;
            city: string | undefined;
            state: string | undefined;
            country: string | undefined;
            zip_code: string;
        };
        holder_level: {
            id: number;
            name: string | undefined;
            description: string | undefined;
            color: "#fff";
            deleted_at: null;
        };
    };
    customer_category: {
        name: string;
    };

    customer_contract: {
        id: number;
        pdf_link: string;
        holder_id: number;
        proposal_id: number;
        created_at: string;
        updated_at: string;
    };

    app_plan: {
        id: number;
        product_id: number;
        description: string | undefined;
        standard_payment_price: number;
        installment_standard: number;
        recurring_payment_price: number;
        installment_recurring: number;
        subscription_standard_payment_price: number;
        subscription_recurring_payment_price: number;
        subscription_annual_payment_price: number;
        period_in_months: number;
        is_active: number;
        deleted_at: null;
        implementation_plans: [
            {
                implementation_id: number;
                implementations: [{ description: string }];
            }
        ];
        mobility_plan: { dead_line: number };
    };
    grace_day: {
        id: number;
        days: number;
        deleted_at: null;
    };
    contract: {
        id: number;
        title: string | undefined;
        content: string | undefined;
        is_active: number;
        deleted_at: null;
    };
    lead_source: {
        id: 1;
        name: string | undefined;
        deleted_at: null;
    };
    payment: [
        {
            id: number;
            amount: number;
            due_date: string | undefined;
            payment_date: null;
            renegotiated: null;
            holder_id: number;
            payment_status_id: number;
            form_payment_id: number;
            proposal_id: number;
            payment_reference: string | undefined;
            deleted_at: null;
            form_payment: {
                id: number;
                number_installments: number;
                due_date: string;
                description: string | undefined;
                type_form_payment_type_id: number;
            };
            payment_status: { name: string };
            use_coupon: {
                coupon: {
                    key: string;
                    value: string;
                    type_id: number;
                };
            };
        }
    ];
    proposal_informations: string;
    proposal_implementations: [
        {
            implementation_id: number;
            implementations: {
                app_module_id: number;
                can_repeat: number;
                created_at: string | Date | null;
                deleted_at: null;
                description: string;
                id: number;
                is_active: number;
                manual_installation: number;
                product_id: number;
                updated_at: string | Date | null;
            };
            price: number;
        }
    ];
    proposal_signatories: [
        {
            holder: {
                full_name: string;
                id: number;
                email: string;
                document: string;
                phone: string;
            };
        }
    ];

    user: { name: string; email: string };
    customer_form?: {
        form_key: string;
    };
}

interface PaymentProps {
    id: number;
    amount: number;
    due_date: string | undefined;
    payment_date: null | string;
    renegotiated: null;
    holder_id: number;
    payment_status_id: number;
    form_payment_id: number;
    proposal_id: number;
    payment_reference: string | undefined;
    deleted_at: null;
    form_payment: {
        id: number;
        number_installments: number;
        due_date: string;
        description: string | undefined;
        type_form_payment_type_id: number;
    };
    payment_status: { name: string };
}
interface NewPaymentErrorsProps {
    due_date?: string;
}
interface CustomersContractsProps {
    id: number;
    ip_address: string;
    signed_at: string;

    holder: {
        id: number;
        full_name: string;
    };
}

function ProposalDetailsPage() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    const currentUrl = window.location.origin;

    const navigate = useNavigate();
    const { hasPermissions, permissions } = useAuth();

    const zipCodeMask = (value: string) => {
        let newValue = value?.replace(/\D/g, "");
        newValue = newValue?.replace(/(\d{5})(\d)/, "$1-$2");
        return newValue;
    };

    const [errors, setErrors] = useState<NewPaymentErrorsProps>();
    const [openToast, setOpenToast] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    function handleCloseToast() {
        setOpenToast(false);
    }
    const [toastRequisitionResult, setToastRequisitionResult] =
        useState<AlertColor>();
    const [textToast, setTextToast] = useState("");

    const [currentProposal, setCurrentProposal] = useState<ProposalProps>();
    const [currentPayment, setCurrentPayment] = useState<PaymentProps>();

    interface NewPaymentProps {
        due_date: string;
    }
    const [newPaymentData, setNewPaymentData] = useState<NewPaymentProps>({
        due_date: new Date().toISOString().substr(0, 10),
    });

    const [listContractsLinks, setListContractsLinks] =
        useState<CustomersContractsProps[]>();

    const [isLoadingCreateContractButton, setIsLoadingCreateContractButton] =
        useState(false);

    const [isDisabledCreateContractButton, setIsDisabledCreateContractButton] =
        useState(false);

    const style = {
        position: "absolute" as const,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [newPaymentLoading, setNewPaymentLoading] = useState(false);

    const createNewPayment = () => {
        setNewPaymentLoading(true);
        api.post(`/proposal/recreate-payment/${currentProposal?.id}`, {
            due_date: newPaymentData.due_date,
        })
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast(
                    "Nova solicitação efetuada com sucesso e cliente notificado! Atualizando em 5 segundos."
                );
                setTimeout(() => {
                    setNewPaymentLoading(false);
                    window.location.reload();
                }, 5000);
            })
            .catch((error) => {
                setNewPaymentLoading(false);
                setOpenToast(true);
                setToastRequisitionResult("error");

                setTextToast(error.response.data.message);
            });
    };

    const [confirmPaymentLoading, setConfirmPaymentLoading] = useState(false);

    const confirmPayment = (arg: any) => {
        setConfirmPaymentLoading(true);
        api.post(`/proposal/approve-payment/${currentProposal?.id}`)
            .then((response) => {
                setOpenToast(true);
                setToastRequisitionResult("success");
                setTextToast("Pagamento confirmado com sucesso!");
                setTimeout(() => {
                    setConfirmPaymentLoading(false);
                    navigate("/comercial/proposal");
                }, 4000);
            })
            .catch((error) => {
                setConfirmPaymentLoading(false);
                setOpenToast(true);
                setToastRequisitionResult("error");

                setTextToast(error.response.data.message);
            });
    };

    const createContractLink = () => {
        setIsLoadingCreateContractButton(true);
        setIsDisabledCreateContractButton(true);
        api.post(`/customerContracts`, {
            proposal_id: Number(myParam),
        })
            .then((response) => {
                setIsLoadingCreateContractButton(false);

                api.get(
                    `contractSignatories/list/${response.data.response.customer_contract.id}`
                )
                    .then((response) => {
                        setListContractsLinks(response.data.response);
                        setIsLoadingCreateContractButton(false);
                    })
                    .catch((error) => {
                        setOpenToast(true);
                        setToastRequisitionResult("error");
                        setTextToast(error.response.data.message);
                        setIsLoadingCreateContractButton(false);
                        setIsDisabledCreateContractButton(false);
                    });
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.response.exception);
                setIsLoadingCreateContractButton(false);
                setIsDisabledCreateContractButton(false);
            });
    };

    // ID DA FATURA

    useEffect(() => {
        if (
            hasPermissions("propostas.detalhar") === false &&
            permissions.length > 0
        ) {
            navigate("/Error", { replace: true });
        }
        api.get(`proposal/details/${myParam}`)
            .then((response) => {
                setIsLoading(false);
                setCurrentProposal(response.data.response[0]);

                const totalPayments = response.data.response[0].payment.length;

                const paymentPaid: PaymentProps =
                    response.data.response[0].payment.filter(
                        (payment: PaymentProps) => payment.payment_date !== null
                    )[0];

                if (paymentPaid === undefined) {
                    setCurrentPayment(
                        response.data.response[0].payment[totalPayments - 1]
                    );
                } else {
                    setCurrentPayment(paymentPaid);
                }

                if (response.data.response[0].customer_contract !== null) {
                    createContractLink();
                }
            })
            .then(() => setIsLoading(false))
            .catch((error) => {
                setOpenToast(true);
                setIsLoading(false);

                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    }, [permissions]);

    // VERIFICA O VALOR DE DATA E TRADUZ PARA A FORMA DE PAGAMENTO
    const selectedPaymentMethod = (data: number) => {
        switch (data) {
            case PAYMENT_LINK_METHOD:
                return "Link de Pagamento";
            case PAYMENT_RECURRING_METHOD:
                return "Recorrente";
            case PAYMENT_CARD_METHOD:
                return "Cartão de Crédito - A vista ou parcelado";
            default:
                return "3";
        }
    };

    const getLinkForEachHolderById = (id: number) => {
        api.get(`contractSignatories/${id}/link`)
            .then((response) => {
                const { link } = response.data.response;
                const textField = document.createElement("textarea");
                textField.innerText = link;
                document.body.appendChild(textField);
                textField.select();
                document.execCommand("copy");
                textField.remove();
            })
            .then(() => {
                setOpenToast(true);
                setTextToast("Link copiado e pronto para ser colado");
            })
            .catch((error) => {
                setOpenToast(true);
                setToastRequisitionResult("error");
                setTextToast(error.response.data.message);
            });
    };

    function formatedDateAndHours(dateAndHoursString: string): string {
        const dateAndHour = new Date(dateAndHoursString);
        const day = dateAndHour.getDate();
        const month = dateAndHour.getMonth() + 1; // Note que janeiro é o mês 0
        const year = dateAndHour.getFullYear();
        const hours = dateAndHour.getHours();
        const minutes = dateAndHour.getMinutes();
        const seconds = dateAndHour.getSeconds();

        const formatedDate = `${day}/${month}/${year} às ${
            hours < 10 ? `0${hours}` : hours
        }:${minutes < 10 ? `0${minutes}` : minutes}:${
            seconds < 10 ? `0${seconds}` : seconds
        }`;
        return formatedDate;
    }

    // LÓGICA PARA IMPLEMENTAÇÃO EXTRA NÃO SER MOSTRADA VÁRIAS VEZES
    type ImplementationCounts = {
        [key: string]: number;
    };
    type ImplementationTotals = {
        [key: string]: number;
    };
    type UniqueImplementations = number[];

    const implementationCounts: ImplementationCounts = {};
    const implementationTotals: ImplementationTotals = {};
    const uniqueImplementations: UniqueImplementations = [];
    currentProposal?.proposal_implementations.forEach(
        ({ implementation_id, price }) => {
            if (!implementationCounts[implementation_id]) {
                implementationCounts[implementation_id] = 0;
                implementationTotals[implementation_id] = 0;
            }
            // eslint-disable-next-line no-plusplus
            implementationCounts[implementation_id]++;
            implementationTotals[implementation_id] += price;
        }
    );

    return (
        <PageWrapper>
            {isLoading ? (
                <PreloadFb />
            ) : (
                <FormProposalDetailsPage
                    isDisabledCreateContractButton={
                        isDisabledCreateContractButton
                    }
                    id="print"
                >
                    <div className="mainContainer">
                        <div className="printButtonContainer">
                            <Print
                                className="iconPrint no-print"
                                onClick={() => window.print()}
                            />
                        </div>
                        <div className="titlePageContainer">
                            <h1>Detalhes da Proposta</h1>
                        </div>
                        <div>
                            <h2>Dados do Vendedor</h2>
                            <div className="contentFields">
                                <div className="contentRows">
                                    <p className="boldText">
                                        {currentProposal?.user?.name}
                                    </p>
                                </div>
                                <div className="contentRows">
                                    <div className="contentRows">
                                        <p className="boldText">
                                            {currentProposal?.user?.email}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="no-print">
                                <h2>Link do Contrato</h2>

                                {currentProposal?.customer_contract === null ? (
                                    <div className="contentFields">
                                        <div className="contentRows">
                                            <p className="boldText">
                                                Clique no botão para gerar o
                                                link de contratos para todos os
                                                assinantes.
                                            </p>
                                        </div>
                                        <div className="buttonLinkContainer">
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    createContractLink()
                                                }
                                                className="buttonCreateLink"
                                                disabled={
                                                    isDisabledCreateContractButton
                                                }
                                            >
                                                {isLoadingCreateContractButton ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={11}
                                                    />
                                                ) : (
                                                    "Gerar Link de Contrato"
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}

                                {listContractsLinks
                                    ? listContractsLinks.map(
                                          ({
                                              holder,
                                              id,
                                              signed_at,
                                              ip_address,
                                          }) => (
                                              <div
                                                  className="informationsLinkContractContainer"
                                                  key={id}
                                              >
                                                  <div>
                                                      <p className="boldText">
                                                          {holder.full_name}
                                                      </p>
                                                      {!signed_at ? (
                                                          <button
                                                              onClick={() => {
                                                                  getLinkForEachHolderById(
                                                                      id
                                                                  );
                                                              }}
                                                              type="button"
                                                          >
                                                              Copiar Link
                                                          </button>
                                                      ) : (
                                                          <>
                                                              <button
                                                                  disabled
                                                                  type="button"
                                                              >
                                                                  Já assinou
                                                              </button>
                                                              <div>
                                                                  <p className="verySmallText">
                                                                      Ip:{" "}
                                                                      {
                                                                          ip_address
                                                                      }
                                                                  </p>
                                                                  <p className="verySmallText">
                                                                      Assinado
                                                                      na data{" "}
                                                                      {formatedDateAndHours(
                                                                          signed_at
                                                                      )}
                                                                  </p>
                                                              </div>
                                                          </>
                                                      )}
                                                  </div>
                                              </div>
                                          )
                                      )
                                    : ""}
                            </div>

                            <h2>Titular da Conta</h2>

                            <div className="contentFields">
                                <div className="contentRows">
                                    <p className="boldText">
                                        {currentProposal?.holder.full_name}
                                    </p>
                                    <p className="boldText">
                                        {currentProposal?.holder.email}
                                    </p>
                                </div>
                                <div className="contentRows">
                                    <p className="boldText">
                                        {documentMask(
                                            currentProposal?.holder
                                                ?.document as string
                                        )}
                                    </p>
                                    <p className="boldText">
                                        {phoneMask(
                                            currentProposal?.holder
                                                ?.phone as string
                                        )}
                                    </p>
                                </div>
                            </div>

                            <h2>Endereço</h2>

                            <div className="contentFields">
                                <div className="contentRows">
                                    <p className="boldText">
                                        {
                                            currentProposal?.holder?.address
                                                ?.street
                                        }{" "}
                                        {
                                            currentProposal?.holder?.address
                                                ?.street_number
                                        }
                                    </p>
                                    <p className="boldText">
                                        {
                                            currentProposal?.holder?.address
                                                ?.neighborhood
                                        }{" "}
                                    </p>
                                    <p className="boldText">
                                        {`CEP (${zipCodeMask(
                                            currentProposal?.holder?.address
                                                ?.zip_code as string
                                        )})`}
                                    </p>
                                </div>
                                <div className="contentRows">
                                    <p className="boldText">
                                        {currentProposal?.holder?.address?.city}
                                    </p>
                                    <p className="boldText">
                                        {
                                            currentProposal?.holder?.address
                                                ?.state
                                        }
                                    </p>
                                    <p className="boldText">
                                        {
                                            currentProposal?.holder?.address
                                                ?.country
                                        }
                                    </p>
                                </div>
                            </div>
                            {currentProposal
                                ? currentProposal.proposal_signatories.length >
                                      0 && <h2>Signatários</h2>
                                : null}

                            {currentProposal?.proposal_signatories
                                ? currentProposal?.proposal_signatories?.map(
                                      ({ holder }) => (
                                          <div
                                              key={holder?.id}
                                              className="contentFieldsToSignatories"
                                          >
                                              <div className="contentRows">
                                                  <p className="boldText">
                                                      {holder?.full_name}
                                                  </p>

                                                  <p className="boldText">
                                                      {holder?.email}
                                                  </p>
                                              </div>
                                              <div className="contentRows">
                                                  <p className="boldText">
                                                      {documentMask(
                                                          holder?.document
                                                      )}
                                                  </p>
                                                  <p className="boldText">
                                                      {phoneMask(holder?.phone)}
                                                  </p>
                                              </div>
                                          </div>
                                      )
                                  )
                                : ""}

                            <h2>Informações Adicionais</h2>
                            <div className="contentFields">
                                <div className="contentRows no-print">
                                    <p className="boldText">Tipo de Titular</p>
                                    <p className="smallText">
                                        {currentProposal?.holder
                                            .holder_level !== null
                                            ? currentProposal?.holder
                                                  .holder_level.name
                                            : "Não identificado"}
                                    </p>
                                </div>
                                <div className="contentRows no-print">
                                    <p className="boldText">Nível de Cliente</p>
                                    <p className="smallText">
                                        {
                                            currentProposal?.customer_category
                                                .name
                                        }
                                    </p>
                                </div>
                                <div className="contentRows">
                                    <p className="boldText">Origem do Lead</p>
                                    <p className="smallText">
                                        {currentProposal?.lead_source.name}
                                    </p>
                                </div>
                                <div className="contentRows">
                                    <p className="boldText">
                                        Informações da Proposta
                                    </p>
                                    <p className="smallText">
                                        {currentProposal?.proposal_informations}
                                    </p>
                                </div>
                            </div>

                            {currentProposal
                                ? currentProposal.app_plan.implementation_plans
                                      .length > 0 && (
                                      <h4>Implementações do Plano</h4>
                                  )
                                : null}

                            <div className="implementationsList">
                                <div className="contentFields">
                                    {currentProposal?.app_plan
                                        .implementation_plans
                                        ? currentProposal?.app_plan.implementation_plans?.map(
                                              ({
                                                  implementation_id,
                                                  implementations,
                                              }) => (
                                                  <div
                                                      key={implementation_id}
                                                      className="contentRows"
                                                  >
                                                      <p className="smallText">
                                                          {
                                                              implementations[0]
                                                                  ?.description
                                                          }
                                                      </p>
                                                  </div>
                                              )
                                          )
                                        : ""}
                                </div>
                            </div>

                            {currentProposal
                                ? currentProposal.proposal_implementations
                                      .length > 0 && (
                                      <h4>Implementações Extras</h4>
                                  )
                                : null}

                            <div className="implementationsList">
                                {currentProposal?.proposal_implementations
                                    ? currentProposal?.proposal_implementations.map(
                                          ({
                                              implementation_id,
                                              implementations,
                                              price,
                                          }) => {
                                              const isDuplicate =
                                                  uniqueImplementations.includes(
                                                      implementation_id
                                                  );

                                              if (isDuplicate) {
                                                  return null; // Ignora a exibição do elemento duplicado
                                              }

                                              uniqueImplementations.push(
                                                  implementation_id
                                              );

                                              return (
                                                  <div
                                                      key={implementation_id}
                                                      className="contentFields"
                                                  >
                                                      <div className="contentRows">
                                                          <p className="smallText">
                                                              {
                                                                  implementations.description as any
                                                              }
                                                          </p>
                                                      </div>
                                                      <div className="contentRows">
                                                          <p className="smallText">
                                                              {price
                                                                  ? `R$ ${formatCurrencyToShow(
                                                                        implementationTotals[
                                                                            implementation_id
                                                                        ]
                                                                    )}`
                                                                  : "Preço Indefinido"}
                                                          </p>
                                                      </div>
                                                  </div>
                                              );
                                          }
                                      )
                                    : ""}
                            </div>
                            <div className="contentFields">
                                <div className="contentRows">
                                    <h5 className="boldText">
                                        Contrato Selecionado
                                    </h5>
                                    <p className="smallText">
                                        {currentProposal?.contract.title}
                                    </p>
                                </div>
                                <div className="contentRows">
                                    <h5 className="boldText">
                                        Plano Selecionado
                                    </h5>
                                    <p className="smallText">
                                        {currentProposal?.app_plan.description}
                                    </p>
                                </div>
                                <div className="contentRows">
                                    <h5 className="boldText">
                                        Validade do Plano
                                    </h5>
                                    <p className="smallText">
                                        {`${currentProposal?.app_plan.period_in_months} meses`}
                                    </p>
                                </div>
                                <div className="contentRows">
                                    <h5 className="boldText">
                                        Prazo de Entrega
                                    </h5>
                                    <p className="smallText">
                                        {`${currentProposal?.app_plan.mobility_plan.dead_line} dias úteis`}
                                    </p>
                                </div>
                            </div>
                            <div className="contentFields">
                                {currentProposal?.payment[0].form_payment
                                    .type_form_payment_type_id === 3 ? (
                                    <>
                                        <div className="contentRows">
                                            <h5 className="boldText">
                                                Valor Total da Ativação
                                            </h5>
                                            <p className="smallText">
                                                R$
                                                {formatCurrencyToShow(
                                                    currentProposal?.app_plan
                                                        .standard_payment_price
                                                )}
                                            </p>
                                        </div>
                                        <div className="contentRows">
                                            <h5 className="boldText">
                                                {" "}
                                                Valor Mensal do Plano
                                            </h5>
                                            <p className="smallText">
                                                R$
                                                {formatCurrencyToShow(
                                                    currentProposal?.app_plan
                                                        .subscription_standard_payment_price
                                                )}
                                            </p>
                                        </div>
                                        <div className="contentRows">
                                            <h5 className="boldText">
                                                {" "}
                                                Número de parcelas selecionado
                                            </h5>
                                            <p className="smallText">
                                                {
                                                    currentProposal?.payment[0]
                                                        .form_payment
                                                        .number_installments
                                                }
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="contentRows">
                                            <h5 className="boldText">
                                                Valor Total do Plano
                                            </h5>
                                            <p className="smallText">
                                                {" "}
                                                R$
                                                {formatCurrencyToShow(
                                                    currentProposal?.app_plan
                                                        .recurring_payment_price
                                                )}
                                            </p>
                                        </div>
                                        <div className="contentRows">
                                            <h5 className="boldText">
                                                Valor da Mensalidade
                                            </h5>
                                            <p className="smallText">
                                                {" "}
                                                R$
                                                {formatCurrencyToShow(
                                                    currentProposal?.app_plan
                                                        .subscription_recurring_payment_price
                                                )}
                                            </p>
                                        </div>
                                        {/* <div className="contentRows">
                                            <h5 className="boldText">
                                                {" "}
                                                Número máximo de parcelas
                                            </h5>
                                            <p className="smallText">
                                                {
                                                    currentProposal?.app_plan
                                                        .installment_recurring
                                                }
                                            </p>
                                        </div> */}
                                        <div className="contentRows">
                                            <h5 className="boldText">
                                                {" "}
                                                Número de parcelas selecionado
                                            </h5>
                                            <p className="smallText">
                                                {
                                                    currentProposal?.payment[0]
                                                        .form_payment
                                                        .number_installments
                                                }
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="contentFields">
                                <div className="contentRows">
                                    <h5 className="boldText">
                                        Período de Carência
                                    </h5>
                                    <p className="smallText">
                                        {currentProposal?.grace_day.days} dias
                                    </p>
                                </div>
                                <div className="contentRows">
                                    <h5 className="boldText">
                                        Método de Pagamento
                                    </h5>
                                    <p className="smallText">
                                        {selectedPaymentMethod(
                                            currentProposal?.payment[0]
                                                .form_payment
                                                .type_form_payment_type_id as number
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="contentFields">
                                <div className="contentRows">
                                    <h5 className="boldText">
                                        Data de Vencimento do pagamento
                                    </h5>
                                    <p className="smallText">
                                        {currentPayment?.due_date
                                            ?.slice(0, 10)
                                            ?.split("-")
                                            ?.reverse()
                                            ?.join("/")}
                                    </p>
                                </div>
                                <div className="contentRows">
                                    <h5 className="boldText">
                                        Status de Pagamento
                                    </h5>
                                    <p className="smallText">
                                        {currentPayment?.payment_status?.name}
                                    </p>
                                </div>
                            </div>
                            <div className="contentFields">
                                <div className="contentRows no-print">
                                    <h5 className="boldText">
                                        Link de pagamento
                                    </h5>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        sx={{
                                            width: "35%",
                                        }}
                                        onClick={handleOpen}
                                    >
                                        Detalhes
                                    </Button>
                                    <PaymentCard>
                                        <Modal
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box sx={style}>
                                                <Typography
                                                    id="modal-modal-title"
                                                    variant="h5"
                                                    component="h2"
                                                >
                                                    <strong>
                                                        Informações do pagamento
                                                    </strong>
                                                </Typography>
                                                <Typography
                                                    id="modal-modal-description"
                                                    sx={{
                                                        my: 2,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div>
                                                        <a
                                                            target="_blank"
                                                            href={`${window.env.REACT_APP_LINK_PAYMENT}checkout?id=${currentPayment?.payment_reference}`}
                                                            className="smallText"
                                                            rel="noreferrer"
                                                        >
                                                            Link de pagamento
                                                        </a>
                                                        <OpenInNew fontSize="small" />
                                                    </div>

                                                    <If
                                                        condition={
                                                            hasPermissions(
                                                                "pagamento.confirmar"
                                                            ) &&
                                                            currentPayment
                                                                ?.payment_status
                                                                ?.name ===
                                                                "Pendente"
                                                        }
                                                    >
                                                        <Tooltip title="cuidado! isso irá confirmar pagamento manualmente.">
                                                            <ConfirmModal
                                                                variant="contained"
                                                                color="warning"
                                                                size="small"
                                                                loading={
                                                                    confirmPaymentLoading
                                                                }
                                                                onClick={
                                                                    confirmPayment
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </If>
                                                </Typography>
                                                <Divider />
                                                <div
                                                    style={{
                                                        marginTop: "22px",
                                                    }}
                                                >
                                                    Criar novo link de pagamento
                                                </div>
                                                <PaymentCardBody>
                                                    <InputGeneric
                                                        // onBlur={onBlur}
                                                        onChange={(e) => {
                                                            setNewPaymentData({
                                                                due_date:
                                                                    e.target
                                                                        .value,
                                                            });
                                                        }}
                                                        size="small"
                                                        value={
                                                            newPaymentData.due_date
                                                        }
                                                        defaultValue={new Date()
                                                            .toISOString()
                                                            .substr(0, 10)}
                                                        className="bigInput"
                                                        type="date"
                                                        label="Data de Vencimento"
                                                        error={
                                                            !!errors?.due_date
                                                        }
                                                        helperText={
                                                            !!errors?.due_date &&
                                                            "Selecione uma Data para confirmar o Vencimento"
                                                        }
                                                        inputProps={{
                                                            maxLength: 10,
                                                        }}
                                                    />
                                                    <div>
                                                        <LoadingButton
                                                            variant="contained"
                                                            color="success"
                                                            onClick={
                                                                createNewPayment
                                                            }
                                                            loading={
                                                                newPaymentLoading
                                                            }
                                                            disabled={
                                                                currentPayment?.payment_date !==
                                                                    null ||
                                                                new Date(
                                                                    currentPayment?.due_date ??
                                                                        ""
                                                                ) > new Date()
                                                            }
                                                        >
                                                            Gerar
                                                        </LoadingButton>
                                                    </div>
                                                </PaymentCardBody>
                                            </Box>
                                        </Modal>
                                    </PaymentCard>
                                </div>
                                {currentProposal?.form_link &&
                                currentProposal?.form_link ? (
                                    <div className="contentRows no-print">
                                        <h5 className="boldText">
                                            Link do formulário ANA
                                        </h5>
                                        <a
                                            target="_blank"
                                            href={`${currentProposal?.form_link}`}
                                            className="smallText"
                                            rel="noreferrer"
                                        >
                                            {`https://page.speedmob.com.br/mobilitypro/informacoes-do-gestor?id=${currentProposal?.form_link}`}
                                        </a>
                                    </div>
                                ) : null}

                                <div className="contentRows">
                                    <h5 className="boldText">
                                        Data de Pagamento
                                    </h5>
                                    <p className="smallText">
                                        {String(
                                            currentPayment?.payment_date ||
                                                "Aguardando"
                                        )
                                            ?.slice(0, 10)
                                            ?.split("-")
                                            ?.reverse()
                                            ?.join("/")}
                                    </p>
                                </div>
                                <If
                                    condition={
                                        currentProposal?.payment[0]
                                            ?.use_coupon !== null
                                    }
                                >
                                    <div className="contentRows">
                                        <h5 className="boldText">
                                            Cupom Usado
                                        </h5>
                                        <p className="smallText">
                                            {
                                                currentProposal?.payment[0]
                                                    ?.use_coupon?.coupon?.key
                                            }{" "}
                                            -{" "}
                                            {currentProposal?.payment[0]
                                                ?.use_coupon?.coupon
                                                ?.type_id === 2
                                                ? `R$${formatCurrencyToShow(
                                                      currentProposal
                                                          ?.payment[0]
                                                          ?.use_coupon?.coupon
                                                          ?.value
                                                  )}`
                                                : `%${formatCurrencyToShow(
                                                      currentProposal
                                                          ?.payment[0]
                                                          ?.use_coupon?.coupon
                                                          ?.value
                                                  )}`}
                                        </p>
                                    </div>
                                </If>
                                <div className="contentRows">
                                    <h5 className="boldText">
                                        Valor de Entrada da Ativação
                                    </h5>
                                    <p className="smallText">
                                        R$
                                        {formatCurrencyToShow(
                                            currentProposal?.payment[0].amount
                                        )}
                                    </p>
                                </div>
                                <If
                                    condition={
                                        currentPayment?.payment_date !== null
                                    }
                                >
                                    <div className="contentRows no-print">
                                        <p className="boldText">Contrato</p>
                                        <p className="smallText">
                                            <a
                                                target="_blank"
                                                href={`${currentProposal?.customer_contract?.pdf_link}`}
                                                className="smallText"
                                                rel="noreferrer"
                                            >
                                                Link do contrato
                                            </a>{" "}
                                        </p>
                                    </div>
                                </If>
                                <If
                                    condition={
                                        currentProposal?.customer_form !==
                                            null &&
                                        currentProposal?.customer_form !==
                                            undefined
                                    }
                                >
                                    <div className="contentRows no-print">
                                        <p className="boldText">Formulário</p>
                                        <p className="smallText">
                                            <a
                                                target="_blank"
                                                href={`https://form.prod.704apps.com.br/mobilitypro/informacoes/${currentProposal?.customer_form?.form_key}`}
                                                className="smallText"
                                                rel="noreferrer"
                                            >
                                                Link do formulário ANA
                                            </a>{" "}
                                        </p>
                                    </div>
                                </If>
                            </div>
                        </div>
                    </div>
                    <Toast
                        open={openToast}
                        onClose={() => handleCloseToast()}
                        severity={toastRequisitionResult}
                        text={textToast}
                    />
                </FormProposalDetailsPage>
            )}
        </PageWrapper>
    );
}

export default ProposalDetailsPage;
