import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";

import { defaultToast } from "../../helpers/toast/defaultToast";
import { api } from "../../services/api";
import ButtonGeneric from "../ButtonGeneric";
import { Container, ContentInput } from "./styles";
import { setMaskMoney } from "../../helpers/maskMoney";
import { formatBrlToNumber } from "../../helpers/formartBrlToNumber";

const schemaForm = yup
    .object({
        name: yup.string().required("Campo obrigatório"),
        description: yup.string().required("Campo obrigatório"),
        value: yup.string().required("Campo obrigatório"),
        installments: yup.number().required("Campo obrigatório"),
    })
    .required();

type SchemaFormProps = yup.InferType<typeof schemaForm>;

interface Props {
    visible: boolean;
    setVisible: (data: boolean) => void;
    idBilling: number;
    getFinancial: () => void;
    setOpenBillingDetailsModal: (data: boolean) => void;
}

function ModalMonthlyBilling({
    visible,
    setVisible,
    idBilling,
    getFinancial,
    setOpenBillingDetailsModal,
}: Props) {
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const { control, handleSubmit, setValue, reset } = useForm<SchemaFormProps>(
        {
            resolver: yupResolver(schemaForm),
        }
    );

    const handleClearDatas = () => {
        reset();
        setVisible(false);
    };

    const handleAddMonthlyBilling = async (data: SchemaFormProps) => {
        try {
            setIsLoadingButton(true);
            const responseAddMonthlyBilling = await api.post(
                `monthly-billing/addBilling/${idBilling}`,
                {
                    name: data.name,
                    description: data.description,
                    amount: formatBrlToNumber(data.value),
                    installments: data.installments,
                }
            );

            if (responseAddMonthlyBilling.status) {
                defaultToast("success", "Faturamento adicionado com sucesso");
                handleClearDatas();
                getFinancial();
                setOpenBillingDetailsModal(false);
            }
        } catch (error) {
            const responseError = error as AxiosError<any, any>;
            defaultToast("error", responseError.response?.data.message);
        } finally {
            setIsLoadingButton(false);
        }
    };

    return (
        <Modal open={visible} onClose={() => setVisible(false)}>
            <Container onSubmit={handleSubmit(handleAddMonthlyBilling)}>
                <h1>Faturamento Mensal</h1>

                <Controller
                    name="name"
                    control={control}
                    render={({
                        field: { onChange },
                        fieldState: { error },
                    }) => {
                        return (
                            <ContentInput>
                                <TextField
                                    onChange={onChange}
                                    fullWidth
                                    size="small"
                                    label="Nome"
                                    error={Boolean(error)}
                                    helperText={error && error.message}
                                />
                            </ContentInput>
                        );
                    }}
                />

                <Controller
                    name="description"
                    control={control}
                    render={({
                        field: { onChange },
                        fieldState: { error },
                    }) => {
                        return (
                            <ContentInput>
                                <TextField
                                    onChange={onChange}
                                    fullWidth
                                    size="small"
                                    label="Descrição"
                                    error={Boolean(error)}
                                    helperText={error && error.message}
                                />
                            </ContentInput>
                        );
                    }}
                />

                <Controller
                    name="value"
                    control={control}
                    render={({ field: { value }, fieldState: { error } }) => {
                        return (
                            <ContentInput>
                                <TextField
                                    onChange={(event) =>
                                        setValue(
                                            "value",
                                            String(
                                                setMaskMoney(event.target.value)
                                            )
                                        )
                                    }
                                    fullWidth
                                    size="small"
                                    label="Valor"
                                    value={value}
                                    error={Boolean(error)}
                                    helperText={error && error.message}
                                />
                            </ContentInput>
                        );
                    }}
                />

                <Controller
                    name="installments"
                    control={control}
                    render={({ field: { value }, fieldState: { error } }) => {
                        return (
                            <ContentInput>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="installments-select-label">
                                        Parcelas
                                    </InputLabel>
                                    <Select
                                        labelId="installments-select-label"
                                        id="installments-select"
                                        value={value}
                                        label="Parcelas"
                                        onChange={(event) =>
                                            setValue(
                                                "installments",
                                                Number(event.target.value)
                                            )
                                        }
                                    >
                                        <MenuItem value={0}>
                                            Sem parcela
                                        </MenuItem>
                                        {Array.from(Array(12).keys()).map(
                                            (installments) => {
                                                return (
                                                    <MenuItem
                                                        value={installments + 1}
                                                        key={installments}
                                                    >
                                                        {installments + 1}
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                    </Select>
                                    {error && (
                                        <FormHelperText error={Boolean(error)}>
                                            {error.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </ContentInput>
                        );
                    }}
                />

                <div className="buttonsContainer">
                    <ButtonGeneric
                        onclick={() => setVisible(false)}
                        buttonColor="var(--cancel)"
                        text="CANCELAR"
                        className="cancelButton"
                    />
                    <LoadingButton
                        title="Salvar"
                        loading={isLoadingButton}
                        type="submit"
                        variant="contained"
                    >
                        Salvar
                    </LoadingButton>
                </div>
            </Container>
        </Modal>
    );
}
export default ModalMonthlyBilling;
